.loader {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    --c: linear-gradient(currentColor 0 0);
    background: var(--c), var(--c), var(--c);
    background-repeat: no-repeat;
    animation: 
      loader-1 1s infinite,
      loader-2 1s infinite;
  }
  
  @keyframes loader-1 {
   0%,100% {background-size:5px 100%}
   33%,66% {background-size:5px 40%}
  }
  
  @keyframes loader-2 {
   0%,33%   {background-position: 0    0,50% 100%,100% 100%}
   66%,100% {background-position: 100% 0,0   100%,50%  100%}
  }